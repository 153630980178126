import React, { useState } from 'react';
import { Button, Modal, Box } from '@mui/material';
import { QRCode } from 'react-qr-svg';

const OrderQRButton = ({ order }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={handleModalOpen}>
        Mostrar QR
      </Button>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center'
          }}
        >
          <QRCode value={order.nombre} />

          <Button variant="contained" onClick={handleModalClose} style={{ marginTop: 16 }}>
            Cerrar
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default OrderQRButton;