import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authFirebase from "../../firebase/authFirebase";
import {
  Grid,
  Container,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  TextField,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { authAction } from "../../redux/authRedux";
import validate from "../../utils/validate";
import LoadingForm from "../../components/LoadingForm";
import AlertForm from "../../components/AlertForm";
import imgLogin from "../../images/calins.webp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const isLoading = useSelector((state) => state.app.isLoading);
  const isSuccessful = useSelector((state) => state.app.isSuccessful);
  const isAuth = useSelector((state) => state.app.isAuth);
  const [error, setError] = useState("");

  const initialData = {
    name: "",
    lastname: "",
    email: "",
    password: "",
  };

  const [data, setData] = useState(initialData);
  const [dataValidate, setDataValidate] = useState(initialData);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    dispatch(authAction.initial());
  }, []);

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  useEffect(() => {
    let validate = initialData;
    for (let err in error) {
      validate = {
        ...validate,
        [err]: error[err][0],
      };
    }
    setDataValidate(validate);
  }, [error]);

  const handleTarget = (ev) => {
    setData({
      ...data,
      [ev.target.name]: ev.target.value,
    });
  };

  const handleBlur = (ev) => {
    const name = ev.target.name;
    if (!data[name].length) {
      setDataValidate({
        ...dataValidate,
        [name]: "",
      });
      return;
    }
    const val = validate(name, data[name]);
    setDataValidate({
      ...dataValidate,
      [name]: val,
    });
  };

  const handleSignUp = async (ev) => {
    setError("");

    try {
      const userCredential = await authFirebase.signUp(
        data.email,
        data.password
      );
      const jwt = userCredential.user.getIdToken();
      localStorage.setItem("auth", JSON.stringify(jwt));

      dispatch(authAction.loginSuccessful(jwt));
    } catch (error) {
      setError(error.message);
    }
  };

  const close = () => {
    setData(initialData);
    dispatch(authAction.initial());
  };

  const splashScreenStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000", // Puedes cambiar el color de fondo según tus necesidades
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };
  const form = () => (
    <>
      <CardContent>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <img src={imgLogin} alt="CALINS S.A." style={imageStyle} />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h5">
                  CALINS S.A.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5">
              Crear cuenta
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="email"
              label="Correo"
              variant="outlined"
              type="text"
              fullWidth
              value={data.email}
              onChange={handleTarget}
              onBlur={handleBlur}
              error={dataValidate.email !== ""}
              helperText={dataValidate.email}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="Contraseña"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={data.password}
              onChange={handleTarget}
              onBlur={handleBlur}
              error={dataValidate.password !== ""}
              helperText={dataValidate.password}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button size="small" variant="text" onClick={() => navigate("/")}>
              ¿Ya tienes cuenta?
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSignUp}>
              Registrarse
            </Button>
          </Grid>
        </Grid>
      </CardActions>
      {error && <Typography color="error">{error}</Typography>}
    </>
  );

  return (
    <Container maxWidth="xs" sx={{ marginTop: 10 }}>
      
      <Card>
        {isLoading ? (
          <LoadingForm />
        ) : isSuccessful ? (
          <AlertForm
            severity="success"
            text="Cuenta registrada con exíto"
            callback={close}
          />
        ) : (
          form()
        )}
      </Card>
    </Container>
  );
};

export default Register;
