import { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { db } from "../../firebase/firebase";
import { getDocs, collection, query, where, limit } from "firebase/firestore";
import { orderBy } from "lodash";
import { updateRol } from "../../firebase/Users";

//correo rol
const roles = ["admin", "técnico", "cliente", "sinrol"];

const Users = () => {
  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroRol, setFiltroRol] = useState("Todos");
  const rolesFiltrables = ["Todos", ...roles];

  const columns = useMemo(
    () => {
      // Agrega una función para manejar el cambio de rol en los botones
      const handleRoleChange = (userId, newRole) => {
        console.log(userId, newRole);
        updateRol(userId, newRole)
          .then(() => {
            // Después de actualizar el rol, vuelve a cargar la lista de usuarios
            fetchLast();
          })
          .catch((error) => {
            console.error("Error al actualizar el rol:", error);
          });
      };
  
      return [
        { field: "correo", headerName: "Email", width: 290 },
        {
          field: "rol",
          headerName: "Rol",
          width: 160,
        },
        {
          field: "actions",
          headerName: "Actions",
          width: 400,
          renderCell: (params) => (
            <div>
              <Button
                variant="outlined"
                onClick={() => handleRoleChange(params.row.id, "sinrol")}
              >
                sinrol
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleRoleChange(params.row.id, "admin")}
              >
                admin
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleRoleChange(params.row.id, "técnico")}
              >
                técnico
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleRoleChange(params.row.id, "cliente")}
              >
                cliente
              </Button>
            </div>
          ),
        },
      ];
    },
    [] // No se necesitan dependencias externas aquí
  );
  
  const fetchLast = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), orderBy("rol", "asc"), limit(20))
      );
      const filteredUsers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error fetching instrumentos:", error);
    }
  };
  //variable de estado
  const [users, setUsers] = useState([]);
  //función para renderizar la lista de usuarios
  useEffect(() => {
    fetchLast();
  }, []);

  const handleSearch = async () => {
    const getLista = async () => {
      console.log("filtroNombre:", filtroNombre);

      let queryRef = collection(db, "users");

      if (filtroRol !== "Todos") {
        queryRef = query(queryRef, where("rol", "==", filtroRol), limit(20));
      } else {
        queryRef = query(
          queryRef,
          where("correo", ">=", filtroNombre),
          limit(20)
        );
      }

      const querySnapShot = await getDocs(queryRef);

      const docs = [];
      querySnapShot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setUsers(docs);
    };
    getLista();
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Lista de usuario
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <TextField
          label="Filtrar por Email"
          value={filtroNombre}
          onChange={(e) => setFiltroNombre(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 16 }}
        />
        <TextField
          select
          label="Filtrar por Rol"
          value={filtroRol}
          onChange={(e) => setFiltroRol(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 16 }}
        >
          {rolesFiltrables.map((rol) => (
            <MenuItem key={rol} value={rol}>
              {rol}
            </MenuItem>
          ))}
        </TextField>
        <Button
          onClick={handleSearch}
          variant="outlined"
          style={{ marginBottom: 16 }}
        >
          Buscar
        </Button>
      </Box>
      <DataGrid
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </Box>
  );
};

export default Users;
