import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Avatar,
  Popover,
  Divider,
  MenuItem,
  ListItemIcon,
  ListItemText, 
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import main from "../styles/main";
import MHidden from "./MHidden";
import { authSelector, authAction } from "../redux/authRedux";
import { navbarAccountItems } from "../services/menuItems";
import avatarDefault from "../images/avatar_default.png";

const MenuPopover = ({ children, sx, ...other }) => {
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{ sx: { ...main.navbar.popover, ...sx } }}
      {...other}
    >
      <Box sx={main.navbar.popoverArrow} />
      {children}
    </Popover>
  );
};

const Account = ({ user, logout }) => {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <IconButton
        ref={menuRef}
        onClick={() => setOpen(true)}
        sx={{
          ...main.navbar.account.icon,
          ...(open && main.navbar.account.iconActive),
        }}
      >
        <Avatar src={avatarDefault} />
      </IconButton>
      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={menuRef.current}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {localStorage.getItem("username")}
          </Typography>
          
        </Box>
        <Divider sx={{ my: 1 }} />
        {navbarAccountItems.map((item) => (
          <MenuItem
            key={item.title}
            to={item.path}
            component={RouterLink}
            onClick={() => setOpen(false)}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <ListItemIcon sx={{ width: 24, height: 24 }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText disableTypography>{item.title}</ListItemText>
          </MenuItem>
        ))}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </Box>
  );
};

const Navbar = ({ onOpenSidebar }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => authSelector.user(state));
  
  const logout = () => {
    localStorage.removeItem("userrol");
    localStorage.removeItem("username");
    dispatch(authAction.logout());
  };

  return (
    <AppBar sx={main.navbar.root} position="static">
      <Toolbar sx={main.navbar.toolabar}>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 2, color: "text.primary" }}
          >
            <MenuIcon />
          </IconButton>
        </MHidden>
        <Typography variant="subtitle1" color="#ff9100" noWrap>
          {localStorage.getItem("userrol").toUpperCase()}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          
            
          
          <Account user={user} logout={logout} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

