import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Typography } from "@mui/material";

const ClientCount = () => {
  const [cantidadClientes, setCantidadClientes] = useState(0);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "empresas")
        );
        const clientesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCantidadClientes(clientesData.length);
      } catch (error) {
        console.error("Error fetching Clientes:", error);
      }
    };

    fetchClientes();
  }, []);

  return (
    <div
      style={{
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "orange",
      }}
    >
      <Typography variant="h1" component="h1" gutterBottom>
        {cantidadClientes}
      </Typography>
    </div>
  );
};

export default ClientCount;