import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

const chartContainerStyles = {
  height: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const colors = [
  "hsl(0, 30%, 50%)",
  "hsl(60, 30%, 50%)",
  "hsl(120, 30%, 50%)",
  "hsl(180, 30%, 50%)",
  "hsl(240, 30%, 50%)",
  "hsl(300, 30%, 50%)",
  // Agrega más colores si es necesario
];



const RolesChart = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Consulta los usuarios desde Firebase Firestore
    const getUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const rolesCount = {};

        //console.log("Calcula la cantidad de usuarios por rol");
        usersSnapshot.forEach((doc) => {
          const { rol } = doc.data();
          if (rolesCount[rol]) {
            rolesCount[rol] += 1;
          } else {
            rolesCount[rol] = 1;
          }
        });

        //console.log("Crea un array de objetos con los datos del gráfico");
        const chartData = Object.keys(rolesCount).map((rol, index) => ({
          name: rol,
          value: rolesCount[rol],
          //fill: `hsl(${(index * 360) / Object.keys(rolesCount).length}, 30%, 50%)`, // Asigna un color único a cada rol
          fill: colors[index % colors.length],
        }));

        setData(chartData);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los usuarios:", error);
      }
    };

    getUsers();
  }, []);

  return (
    <div style={chartContainerStyles}>
      {loading ? (
        <CircularProgress />
      ) : (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey="value"
              data={data}
              innerRadius={30}
              outerRadius={50}
              label
              blendStroke
              strokeWidth={2}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={entry.fill} />
              ))}
            </Pie>
            <Legend />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default RolesChart;
