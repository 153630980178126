import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  
} from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

const chartContainerStyles = {
  height: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "1rem",
};

const colors = [
  "hsl(0, 30%, 50%)",
  "hsl(60, 30%, 50%)",
  "hsl(120, 30%, 50%)",
  "hsl(180, 30%, 50%)",
  "hsl(240, 30%, 50%)",
  "hsl(300, 30%, 50%)",
  // Agrega más colores si es necesario
];

const ServicesChart = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Consulta las Servicess desde Firebase Firestore
    const getServices = async () => {
      try {
        const ServicesCollection = collection(db, "servicios");
        const ServicesSnapshot = await getDocs(ServicesCollection);
        const stateCount = {};

        ServicesSnapshot.forEach((doc) => {
          const { estadoServicio } = doc.data();
          if (stateCount[estadoServicio]) {
            stateCount[estadoServicio] += 1;
          } else {
            stateCount[estadoServicio] = 1;
          }
        });

        const chartData = Object.keys(stateCount).map((state, index) => ({
          estado: state,
          cantidad: stateCount[state],
          fill: colors[index % colors.length],
        }));

        setData(chartData);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener las Services:", error);
      }
    };

    getServices();
  }, []);

  return (
    <div style={chartContainerStyles}>
      {loading ? (
        <CircularProgress />
      ) : (
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid  />
            <XAxis dataKey="estado" />
            <YAxis />
            <Tooltip />
            
            <Bar dataKey="cantidad" fill={colors[0]} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ServicesChart;
