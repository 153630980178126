import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";

import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Modal,
} from "@mui/material";
import { AddCircleOutline, Edit, Delete } from "@mui/icons-material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OrderPDF from "../../components/OrderPdf";
import OrderQRButton from "../../components/OrderQRButton";

const ServiceOrder = () => {
  const [orders, setOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubmitText, setModalSubmitText] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [nombre, setNombre] = useState("");
  const [estado, setEstado] = useState("");
  const [filtroNombre, setFiltroNombre] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        let q = collection(db, "ordenesDeServicio");

        if (filtroNombre.length >= 2) {
          q = query(q, where("nombre", "==", filtroNombre));
        }

        const querySnapshot = await getDocs(q);
        const ordersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [filtroNombre]);

  const handleModalOpen = (title, submitText, order = null) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalSubmitText(submitText);
    setSelectedOrder(order);
    setNombre(order ? order.nombre : "");
    setEstado(order ? order.estado : "");
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedOrder(null);
    setNombre("");
    setEstado("");
  };

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handleEstadoChange = (event) => {
    setEstado(event.target.value);
  };

  const handleFiltroNombreChange = (event) => {
    setFiltroNombre(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedOrder) {
      // Update existing order
      try {
        await updateDoc(doc(db, "ordenesDeServicio", selectedOrder.id), {
          nombre: nombre,
          estado: estado,
        });

        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === selectedOrder.id
              ? { ...order, nombre: nombre, estado: estado }
              : order
          )
        );

        handleModalClose();
      } catch (error) {
        console.error("Error updating order:", error);
      }
    } else {
      // Add new order
      try {
        const docRef = await addDoc(collection(db, "ordenesDeServicio"), {
          nombre: nombre,
          estado: estado,
        });

        setOrders((prevOrders) => [
          ...prevOrders,
          { id: docRef.id, nombre: nombre, estado: estado },
        ]);

        handleModalClose();
      } catch (error) {
        console.error("Error adding order:", error);
      }
    }
  };

  const handleEdit = (order) => {
    handleModalOpen("Editar Orden", "Guardar Cambios", order);
  };

  const handleDelete = async (order) => {
    try {
      await deleteDoc(doc(db, "ordenesDeServicio", order.id));

      setOrders((prevOrders) => prevOrders.filter((o) => o.id !== order.id));
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };
  const handleAddOrderClick = () => {
    // Navegar a la ruta /newservice cuando se hace clic en el botón
    //navigate("/newservice");
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Lista de Órdenes de Servicio
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Button
        onClick={handleAddOrderClick}
        variant="outlined"
        startIcon={<AddCircleOutline />}
        style={{ marginBottom: 16 }}
      >
        Agregar Orden
      </Button>
        <TextField
          label="Filtrar por Id"
          value={filtroNombre}
          onChange={handleFiltroNombreChange}
          variant="outlined"
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.nombre}</TableCell>
                <TableCell>{order.estado}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => handleEdit(order)}
                    aria-label="Editar"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(order)}
                    aria-label="Eliminar"
                  >
                    <Delete />
                  </IconButton>
                  <OrderQRButton order={order}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PDFDownloadLink
        document={<OrderPDF orders={orders} />}
        fileName="ordenes.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Generando PDF..." : "Descargar PDF"
        }
      </PDFDownloadLink>
      

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {modalTitle}
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              required
              fullWidth
              label="Nombre"
              value={nombre}
              onChange={handleNombreChange}
              variant="outlined"
              margin="normal"
            />

            <TextField
              required
              fullWidth
              label="Estado"
              value={estado}
              onChange={handleEstadoChange}
              variant="outlined"
              margin="normal"
            />

            <Button type="submit" variant="outlined">
              {modalSubmitText}
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default ServiceOrder;
