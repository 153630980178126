import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  title: {
    fontSize: 24,
    marginBottom: 10
  },
  orderItem: {
    marginBottom: 5
  },
  qrCodeContainer: {
    marginTop: 5,
    alignSelf: 'center'
  },
  qrCodeImage: {
    width: 250,
    height: 250,
    objectFit: 'contain'
  }
});

const OrderPDF = ({ orders }) => {
  const generateQRCode = async (text) => {
    try {
      const options = {
        errorCorrectionLevel: 'H', // Ajusta el nivel de corrección de errores (L, M, Q, H)
        type: 'image/png', // Cambia el formato de salida a PNG
        quality: 1, // Ajusta la calidad de la imagen (0 - 1)
        margin: 1, // Ajusta el margen del código QR
      };
      return await QRCode.toDataURL(text, options);
    } catch (error) {
      console.error('Error generating QR code:', error);
      return null;
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Órdenes de Servicio</Text>
          {orders.map((order, index) => (
            <View key={index}>
              <Text style={styles.orderItem}>
                - {order.nombre}: {order.estado}
              </Text>
              <View style={styles.qrCodeContainer}>
                <Image style={styles.qrCodeImage} src={generateQRCode(order.nombre)} />
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default OrderPDF;
