// App.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// import theme from "./styles/theme/index";
import { authSelector, authAction } from "./redux/authRedux";
import LoadingPage from "./components/LoadingPage";
import Router from "./pages/routes";
// import { useState } from "react";
// import SplashScreen from "./components/SplashScreen";

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => authSelector.isLoading(state));
  const isAuth = useSelector((state) => authSelector.isAuth(state));
  // const [isLoadingApp, setIsLoadingApp] = useState(true);

  // useEffect(() => {
  //   // Simula una carga asíncrona
  //   setTimeout(() => {
  //     setIsLoadingApp(false);
  //   }, 1500); // Aquí puedes ajustar la duración del splash screen
  // }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(authAction.loginSuccessful());
    } else {
      dispatch(authAction.logout());
    }
  }, [isAuth, dispatch]);

  if (isLoading && isAuth) {
    return <LoadingPage />;
  }

  return (
    <HelmetProvider>
      {/* <ThemeProvider theme={theme}> */}
        <CssBaseline />
        <BrowserRouter>
          {/* {isLoadingApp ? <SplashScreen /> : <Router />} */}
          <Router />
        </BrowserRouter>
      {/* </ThemeProvider> */}
    </HelmetProvider>
  );
};

export default App;
