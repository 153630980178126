import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Typography } from "@mui/material";

const ContactsCount = () => {
  const [cantidadContactsos, setCantidadContactsos] = useState(0);

  useEffect(() => {
    const fetchContactsos = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "contactos")
        );
        const ContactsosData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCantidadContactsos(ContactsosData.length);
      } catch (error) {
        console.error("Error fetching Contactsos:", error);
      }
    };

    fetchContactsos();
  }, []);

  return (
    <div
      style={{
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "orange",
      }}
    >
      <Typography variant="h1" component="h1" gutterBottom>
        {cantidadContactsos}
      </Typography>
    </div>
  );
};

export default ContactsCount;