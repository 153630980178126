import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AddOutlined, CloseOutlined, SaveOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { useParams } from "react-router-dom";

let itemIdCounter = 0;

let date = new Date();
/* Date format you have */
let dateMDY = `${date.getDate().toString().padStart(2, "0")}/${(
  date.getMonth() + 1
)
  .toString()
  .padStart(2, "0")}/${date.getFullYear()}`;
/* Date converted to MM-DD-YYYY format */

const NewService = () => {
  const { empresaId, empresaName } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState({
    servicioId: "",
    revision: "0",
    ultimaRevision: dateMDY,
    nroEmpresa: empresaId,
    observaciones: "",
    estadoOferta: "Cotizado",
    estadoServicio: "Esperando",
    estadoFacturacion: "A Pagar",
    ordenCompra: "0",
    remitoSalida: "0",
    costoTotal: "0",
  });
  const [itemsEnMemoria, setItemsEnMemoria] = useState([]);
  const [nuevoItem, setNuevoItem] = useState("");
  const [nuevoCostoUnitario, setNuevoCostoUnitario] = useState("");
  const [grillaItems, setGrillaItems] = useState([]);
  const [costoTotal, setCostoTotal] = useState(0);
  const [moneda, setMoneda] = useState("");
  const [condicionesComerciales, setCondicionesComerciales] = useState("");
  const [contactoSolicitado, setContactoSolicitado] = useState("");
  const [nroOrdenCompra, setNroOrdenCompra] = useState("");
  const [nroRemito, setNroRemito] = useState("");
  const [fechaRemito, setFechaRemito] = useState("");
  const [nroFactura, setNroFactura] = useState("");
  const [fechaEmisionFactura, setFechaEmisionFactura] = useState("");
  const [fechaVencimientoFactura, setFechaVencimientoFactura] = useState("");
  const [emailFacturacion, setEmailFacturacion] = useState("");
  const [nroNotaCredito, setNroNotaCredito] = useState("");
  const [fechaNotaCredito, setFechaNotaCredito] = useState("");
  const [nroNotaDebito, setNroNotaDebito] = useState("");
  const [fechaNotaDebito, setFechaNotaDebito] = useState("");

  // Función para generar un servicio con un servicioId autoincremental
  const generarServicioAutoincremental = async () => {
    try {
      // Obtén la última entrada en la colección ordenada por servicioId
      const q = query(
        collection(db, "servicios"),
        orderBy("servicioId", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      let ultimoServicioId = 0;

      if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc) => {
          // Obtén el valor del último servicioId y conviértelo en número
          ultimoServicioId = parseInt(doc.data().servicioId, 10);
        });
      }

      // Incrementa el valor para el próximo servicio
      const nuevoServicioId = ultimoServicioId + 1;

      // Calcula el costo total nuevamente antes de guardarlo en Firebase
      const nuevoCostoTotal = itemsEnMemoria.reduce(
        (total, item) => total + parseFloat(item.costoUnitario),
        0
      );

      // Crea un nuevo servicio en la colección
      await addDoc(collection(db, "servicios"), {
        servicioId: nuevoServicioId.toString(), // Convierte de nuevo a cadena si es necesario
        revision: service.revision,
        ultimaRevision: service.ultimaRevision,
        nroEmpresa: service.nroEmpresa,
        observaciones: service.observaciones,
        estadoOferta: service.estadoOferta,
        estadoServicio: service.estadoServicio,
        estadoFacturacion: service.estadoFacturacion,
        ordenCompra: service.ordenCompra,
        remitoSalida: service.remitoSalida,
        costoTotal: nuevoCostoTotal, // Guarda el costo total
        moneda,
        condicionesComerciales,
        contactoSolicitado,
        nroOrdenCompra,
        nroRemito,
        fechaRemito,
        nroFactura,
        fechaEmisionFactura,
        fechaVencimientoFactura,
        emailFacturacion,
        nroNotaCredito,
        fechaNotaCredito,
        nroNotaDebito,
        fechaNotaDebito,
      });

      // Agrega los datos de "item" y "costoUnitario" en la colección "servicioDetalle"
      for (const dato of itemsEnMemoria) {
        await addDoc(collection(db, "servicioDetalle"), {
          servicioId: nuevoServicioId.toString(),
          item: dato.item,
          costoUnitario: dato.costoUnitario,
        });
      }

      // Limpia la grilla en memoria después de guardar los datos en Firebase
      setItemsEnMemoria([]);

      // Actualiza el estado o realiza cualquier otra acción necesaria
      setService({
        servicioId: "", // Convierte de nuevo a cadena si es necesario
        revision: "0",
        ultimaRevision: dateMDY,
        nroEmpresa: empresaId,
        observaciones: "",
        estadoOferta: "Cotizado",
        estadoServicio: "Esperando",
        estadoFacturacion: "A Pagar",
        ordenCompra: "0",
        remitoSalida: "0",
        costoTotal: "0",
      });

      navigate("/services");
    } catch (error) {
      console.error("Error al generar un servicio autoincremental:", error);
    }
  };

  const handleChange = (e) => {
    setService({
      ...service,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {};

  const volverAServicios = (e) => {
    navigate("/services");
  };

  const agregarItemEnMemoria = () => {
    if (nuevoItem !== "" && nuevoCostoUnitario !== "") {
      const nuevoDato = {
        id: itemIdCounter,
        item: nuevoItem,
        costoUnitario: nuevoCostoUnitario,
      };
      console.log("itemid", itemIdCounter);
      // Incrementa el contador de identificadores
      itemIdCounter++;

      // Agrega el nuevo dato a la lista en memoria
      setItemsEnMemoria([...itemsEnMemoria, nuevoDato]);

      // Agrega el nuevo dato a la grilla
      setGrillaItems([...grillaItems, nuevoDato]);

      // Calcula el nuevo costo total
      const nuevoCosto = itemsEnMemoria.reduce(
        (total, item) => total + parseFloat(item.costoUnitario),
        parseFloat(nuevoCostoUnitario)
      );

      // Actualiza el estado del costo total
      setCostoTotal(nuevoCosto);

      // Limpia los campos de entrada después de agregar el dato
      setNuevoItem("");
      setNuevoCostoUnitario("");
    }
  };

  const borrarItem = (id) => {
    console.log("itemid a borrar", id);
    // Filtra los elementos para eliminar el que tenga el id correspondiente solo de la grilla
    const nuevosItemsEnGrilla = grillaItems.filter((item) => item.id !== id);

    // Actualiza la grilla
    setGrillaItems(nuevosItemsEnGrilla);

    // Calcula el nuevo costo total
    const nuevoCosto = nuevosItemsEnGrilla.reduce(
      (total, item) => total + parseFloat(item.costoUnitario),
      0
    );

    // Actualiza el estado del costo total
    setCostoTotal(nuevoCosto);
  };

  return (
    <Paper>
      <Grid container component="form" onSubmit={handleSubmit}>
        <Grid item container xs={12} spacing={1} alignItems="center">
          <Grid item xs={12}>
            Nro Servicio: Asignado al guardar -- Revisión: 0
          </Grid>
          <Grid item xs={4}>
            Fecha: {service.ultimaRevision}
          </Grid>

          <Grid item xs={8}>
            Empresa: {service.nroEmpresa} - {empresaName}
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="observaciones"
              fullWidth
              label="Observaciones"
              value={service.observaciones}
              onChange={handleChange}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            Costo Total: {costoTotal}
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nuevoItem"
              fullWidth
              label="Nuevo Item"
              value={nuevoItem}
              onChange={(e) => setNuevoItem(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nuevoCostoUnitario"
              fullWidth
              label="Nuevo Costo Unitario"
              value={nuevoCostoUnitario}
              onChange={(e) => setNuevoCostoUnitario(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<AddOutlined />}
              onClick={agregarItemEnMemoria}
            >
              Agregar Item
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Costo Unitario</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grillaItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.item}</TableCell>
                      <TableCell>{item.costoUnitario}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => borrarItem(item.id)}
                        >
                          Borrar Item
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="moneda"
              fullWidth
              label="Moneda"
              value={moneda}
              onChange={(e) => setMoneda(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="condicionesComerciales"
              fullWidth
              label="Condiciones Comerciales"
              value={condicionesComerciales}
              onChange={(e) => setCondicionesComerciales(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="contactoSolicitado"
              fullWidth
              label="Contacto Solicitado"
              value={contactoSolicitado}
              onChange={(e) => setContactoSolicitado(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nroOrdenCompra"
              fullWidth
              label="Nro Orden de Compra"
              value={nroOrdenCompra}
              onChange={(e) => setNroOrdenCompra(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nroRemito"
              fullWidth
              label="Nro Remito"
              value={nroRemito}
              onChange={(e) => setNroRemito(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="fechaRemito"
              fullWidth
              label="Fecha de Remito"
              value={fechaRemito}
              onChange={(e) => setFechaRemito(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nroFactura"
              fullWidth
              label="Nro Factura"
              value={nroFactura}
              onChange={(e) => setNroFactura(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="fechaEmisionFactura"
              fullWidth
              label="Fecha de Emisión de Factura"
              value={fechaEmisionFactura}
              onChange={(e) => setFechaEmisionFactura(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="fechaVencimientoFactura"
              fullWidth
              label="Fecha de Vencimiento de Factura"
              value={fechaVencimientoFactura}
              onChange={(e) => setFechaVencimientoFactura(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="emailFacturacion"
              fullWidth
              label="Email de Facturación"
              value={emailFacturacion}
              onChange={(e) => setEmailFacturacion(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nroNotaCredito"
              fullWidth
              label="Nro de Nota de Crédito"
              value={nroNotaCredito}
              onChange={(e) => setNroNotaCredito(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="fechaNotaCredito"
              fullWidth
              label="Fecha de Nota de Crédito"
              value={fechaNotaCredito}
              onChange={(e) => setFechaNotaCredito(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nroNotaDebito"
              fullWidth
              label="Nro de Nota de Débito"
              value={nroNotaDebito}
              onChange={(e) => setNroNotaDebito(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="fechaNotaDebito"
              fullWidth
              label="Fecha de Nota de Débito"
              value={fechaNotaDebito}
              onChange={(e) => setFechaNotaDebito(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              startIcon={<SaveOutlined />}
              onClick={generarServicioAutoincremental}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={volverAServicios}
              variant="contained"
              startIcon={<CloseOutlined />}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NewService;
