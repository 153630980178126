import React, { useState, useEffect } from "react";
import authFirebase from "../../firebase/authFirebase";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import imgLogin from "../../images/calins.webp";
import SplashScreen from "../../components/SplashScreen";
import {
  TextField,
  Button,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  CardActions,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { authAction } from "../../redux/authRedux";
import { getRol } from "../../firebase/Users";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  //const isLoading = useSelector((state) => authSelector.isLoading(state));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    dispatch(authAction.initial());
  }, [dispatch]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    setError("");
    setLoading(true);
    try {
      const userCredential = await authFirebase.signIn(email, password);

      await getRol(userCredential.user.email);

      const jwt = userCredential.user.getIdToken();

      localStorage.setItem("auth", JSON.stringify(jwt));

      dispatch(authAction.loginSuccessful(jwt));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const userCredential = await authFirebase.signInWithGoogle();
      await getRol(userCredential.user.email);

      const jwt = userCredential.user.getIdToken();
      localStorage.setItem("auth", JSON.stringify(jwt));

      dispatch(authAction.loginSuccessful(jwt));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoToRegister = () => {
    navigate("/register");
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  return (
    <Container maxWidth="xs" sx={{ marginTop: 10 }}>
      {/* Muestra el SplashScreen si el estado de carga está activado */}
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <img
                        src={imgLogin}
                        alt="CALINS S.A."
                        style={imageStyle}
                      />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom variant="h5">
                        CALINS S.A.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5">
                    Login
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    fullWidth
                    autoFocus
                    onChange={handleEmailChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" onClick={handleLogin}>
                Con Correo
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleGoogleLogin}
              >
                Con Google
              </Button>
              <Button variant="text" onClick={handleGoToRegister}>
                Register
              </Button>
            </CardActions>
            <Grid item xs={12}>
              {error && <Typography color="error">{error}</Typography>}
            </Grid>
          </Card>
        </>
      )}
    </Container>
  );
};

export default Login;
