import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBhaj-VvinWwA2XB5OOQiRVlZKeSuVRBzw",
  authDomain: "appcalins-89a7b.firebaseapp.com",
  projectId: "appcalins-89a7b",
  storageBucket: "appcalins-89a7b.appspot.com",
  messagingSenderId: "1035390112692",
  appId: "1:1035390112692:web:94a04e8ef592fe5a8b98e1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
  
