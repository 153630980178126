// Routes.js
import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../redux/authRedux";
import DashboardLayout from "./layouts/DashboardLayout";
import NotFound from "./layouts/NotFound";
import Dashboard from "./dashboard/Dashboard";
import Login from "./auth/Login";
import Users from "./users/Users";
import Customers from "./customers/Customers";
import Services from "./servicesCalins/Services";
import Register from "./auth/Register";
import Home from "./home/Home";
import QRCodeReader from "../components/QRCodeReader";
import ServiceOrder from "../pages/serviceorder/ServiceOrder";
import NewService from "./servicesCalins/NewService";
import Contacts from "./contacts/Contacts";
import DashboardTecnico from "./dashboard/DashboardTecnico";
import DashboardCliente from "./dashboard/DashboardCliente";

const Router = () => {
  const isAuth = useSelector((state) => authSelector.isAuth(state));
  const role = localStorage.getItem("userrol");


  return useRoutes([
    { 
      path: "/", 
      element: !isAuth ? <Navigate to="/login" replace={true}/> : role === "admin" | role === "técnico" | role === "cliente" ? <DashboardLayout /> : <Home />
      , children: [
        { element: <Navigate to="/" replace /> },
        { path: '', element: role === "admin" ? <Dashboard /> : role === "técnico" ? <DashboardTecnico /> : role === "cliente" ? <DashboardCliente /> : null},
        { path: 'users', element: <Users /> },
        { path: 'customers', element: <Customers /> },
        { path: 'contacts/:empresaId', element: <Contacts /> },
        { path: 'services', element: <Services /> },
        { path: 'qrcodereader', element: <QRCodeReader /> },
        { path: 'serviceorder', element: <ServiceOrder /> },
        { path: 'newservice/:empresaId/:empresaName', element: <NewService /> },
    ] },
    { path: "/login", element: !isAuth ? <Login /> : <Navigate to="/" replace={true} /> },
    { path: "/register", element: !isAuth ? <Register /> : <Navigate to="/" replace={true} /> },
    { path: "/404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/" replace={true} /> }
  ]);
};

export default Router;
