import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme/index";

import store from "./redux/store";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();