import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import LogoCalins from "../images/calins.webp";
import { Grid, Box, Container } from "@mui/material";

const SplashScreen = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <img src={LogoCalins} alt="Splash Screen" />
        </Grid>
        <Grid item xs={12} >
          <LinearProgress />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SplashScreen;
