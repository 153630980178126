import { db } from "./firebase";
import { collection, getDocs, query, where, updateDoc , doc} from "firebase/firestore";

export const getUser = async (correo) => {
  const querySnapshot = await getDocs(
    query(collection(db, "users"), where("correo", "==", correo.toLowerCase()))
  );
  return querySnapshot;
};

export const updateRol = async (id, role) => {
  const userDoc = doc(db, "users", id)
  await updateDoc(userDoc, {rol: role} )
};

export async function getRol(correo) {
  try {
    
    const docSnapshot = await getUser(correo);

    
    const promises = docSnapshot.docs.map((doc) => {
      
      return new Promise((resolve) => {
        localStorage.setItem("userrol", doc.data().rol);
        localStorage.setItem("username", doc.data().correo);
        resolve(doc.data());
      });
    });

    await Promise.all(promises);
    
    const userData = promises[0];
    return [userData.rol, userData.correo];
  } catch (error) {
    console.log(error.message);
  }
}





