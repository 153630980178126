import { SET_COUNTER } from "../constants";

const stateInitial = {
    isLoading: false,
    isAuth: false,
    isSuccessful: false,
    user: null,
    error: null,
    counter:0,
    isOpenSidebar: false
  };
  
  export const appReducer = (state=stateInitial,action) => {
    if (action.type === 'SET_COUNTER') {
      return {
        ...state,
        counter:action.value
      }
    } 
  
    return {...state}
  }