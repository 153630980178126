import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  query,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {
  Box,
  Typography,
  TextField,
  Button,
  TableCell,
  // IconButton,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import {
  // ContactEmergency,
  AddCircleOutline,
  // Edit,
  // Delete,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";

const Customers = () => {
  const [empresas, setEmpresas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubmitText, setModalSubmitText] = useState("");
  const [selectedEmpresa, setSelectedEmpresa] = useState(null);
  const [nroEmpresa, setNroEmpresa] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [direccion, setDireccion] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [provincia, setProvincia] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [telefono, setTelefono] = useState("");
  const [emailFacturacion, setEmailFacturacion] = useState("");
  const [cuit, setCuit] = useState("");
  const [tipoCliente, setTipoCliente] = useState("");
  const [web, setWeb] = useState("");
  const [pais, setPais] = useState("");
  const [filtroNombre, setFiltroNombre] = useState("");

  const [modalContactOpen, setModalContactOpen] = useState(false);
  const [modalContactTitle, setModalContactTitle] = useState("");
  const [modalContactSubmitText, setModalContactSubmitText] = useState("");
  const [nombreApellido, setNombreApellido] = useState("");
  const [telefonoFijo, setTelefonoFijo] = useState("");
  const [telefonoMovil, setTelefonoMovil] = useState("");
  const [posicion, setPosicion] = useState("");
  const [sector, setSector] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLast = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "empresas"), orderBy("nroEmpresa", "desc"), limit(20))
        );
        const filteredEmpresas = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmpresas(filteredEmpresas);
      } catch (error) {
        console.error("Error fetching instrumentos:", error);
      }
    };
  
    fetchLast();
  }, []);

  const handleSearch = async () => {
    const fetchEmpresas = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "empresas"),
            where("razonSocial", ">=", filtroNombre),
            limit(20)
          )
        );
        const empresasData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const filteredEmpresas = empresasData.filter((empresa) => {
          // Realiza la comparación de búsqueda en la propiedad 'razonSocial' de la empresa
          const razonSocial = empresa.razonSocial.toLowerCase();
          const filtroNombreLowerCase = filtroNombre.toLowerCase();

          return razonSocial.includes(filtroNombreLowerCase);
        });

        setEmpresas(filteredEmpresas);
      } catch (error) {
        console.error("Error fetching empresas:", error);
      }
    };

    fetchEmpresas();
  };

  const handleModalOpen = (title, submitText, empresa = null) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalSubmitText(submitText);
    setSelectedEmpresa(empresa);
    setNroEmpresa(empresa ? empresa.nroEmpresa : "");
    setRazonSocial(empresa ? empresa.razonSocial : "");
    setDireccion(empresa ? empresa.direccion : "");
    setCodigoPostal(empresa ? empresa.codigoPostal : "");
    setProvincia(empresa ? empresa.provincia : "");
    setLocalidad(empresa ? empresa.localidad : "");
    setTelefono(empresa ? empresa.telefono : "");
    setEmailFacturacion(empresa ? empresa.emailFacturacion : "");
    setCuit(empresa ? empresa.cuit : "");
    setTipoCliente(empresa ? empresa.tipoCliente : "");
    setWeb(empresa ? empresa.web : "");
    setPais(empresa ? empresa.pais : "");
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedEmpresa(null);
    setNroEmpresa("");
    setRazonSocial("");
    setDireccion("");
    setCodigoPostal("");
    setProvincia("");
    setLocalidad("");
    setTelefono("");
    setEmailFacturacion("");
    setCuit("");
    setTipoCliente("");
    setWeb("");
    setPais("");
  };

  const handleModalContactOpen = (title, submitText, empresa = null) => {
    setModalContactOpen(true);
    setModalContactTitle(title);
    setModalContactSubmitText(submitText);
    setSelectedEmpresa(empresa);
    setNroEmpresa(empresa ? empresa.nroEmpresa : "");
    setNombreApellido("");
    setTelefonoFijo("");
    setTelefonoMovil("");
    setPosicion("");
    setSector("");
    setEmail("");
  };

  const handleModalContactClose = () => {
    setModalContactOpen(false);
    setSelectedEmpresa(null);
    setNroEmpresa("");
    setNombreApellido("");
    setTelefonoFijo("");
    setTelefonoMovil("");
    setPosicion("");
    setSector("");
    setEmail("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedEmpresa) {
      // Update existing empresa
      try {
        await updateDoc(doc(db, "empresas", selectedEmpresa.id), {
          nroEmpresa: nroEmpresa,
          razonSocial: razonSocial,
          direccion: direccion,
          codigoPostal: codigoPostal,
          pais: pais,
          provincia: provincia,
          localidad: localidad,
          telefono: telefono,
          emailFacturacion: emailFacturacion,
          cuit: cuit,
          tipoCliente: tipoCliente,
          web: web,
        });

        setEmpresas((prevEmpresas) =>
          prevEmpresas.map((empresa) =>
            empresa.id === selectedEmpresa.id
              ? {
                  ...empresa,
                  nroEmpresa: nroEmpresa,
                  razonSocial: razonSocial,
                  direccion: direccion,
                  codigoPostal: codigoPostal,
                  pais: pais,
                  provincia: provincia,
                  localidad: localidad,
                  telefono: telefono,
                  emailFacturacion: emailFacturacion,
                  cuit: cuit,
                  tipoCliente: tipoCliente,
                  web: web,
                }
              : empresa
          )
        );

        handleModalClose();
      } catch (error) {
        console.error("Error updating empresa:", error);
      }
    } else {
      // Add new empresa
      try {
        const docRef = await addDoc(collection(db, "empresas"), {
          nroEmpresa: nroEmpresa,
          razonSocial: razonSocial,
          direccion: direccion,
          codigoPostal: codigoPostal,
          pais: pais,
          provincia: provincia,
          localidad: localidad,
          telefono: telefono,
          emailFacturacion: emailFacturacion,
          cuit: cuit,
          tipoCliente: tipoCliente,
          web: web,
        });

        setEmpresas((prevEmpresas) => [
          ...prevEmpresas,
          {
            id: docRef.id,
            nroEmpresa: nroEmpresa,
            razonSocial: razonSocial,
            direccion: direccion,
            codigoPostal: codigoPostal,
            pais: pais,
            provincia: provincia,
            localidad: localidad,
            telefono: telefono,
            emailFacturacion: emailFacturacion,
            cuit: cuit,
            tipoCliente: tipoCliente,
            web: web,
          },
        ]);

        handleModalClose();
      } catch (error) {
        console.error("Error adding empresa:", error);
      }
    }
  };

  const handleContactSubmit = async (event) => {
    event.preventDefault();

    try {
      await addDoc(collection(db, "contactos"), {
        nroEmpresa: nroEmpresa,
        apellidoNombre: nombreApellido,
        telefonoFijo: telefonoFijo,
        telefonoMovil: telefonoMovil,
        posicion: posicion,
        sector: sector,
        email: email,
      });
      console.log("Nuevo Contacto", nroEmpresa);

      handleModalContactClose();
    } catch (error) {
      console.error("Error adding contacto:", error);
    }
  };

  const handleEdit = (empresa) => {
    handleModalOpen("Editar Empresa", "Guardar Cambios", empresa);
  };

  const handleDelete = async (empresa) => {
    try {
      await deleteDoc(doc(db, "empresas", empresa.id));

      setEmpresas((prevEmpresas) =>
        prevEmpresas.filter((e) => e.id !== empresa.id)
      );
    } catch (error) {
      console.error("Error deleting empresa:", error);
    }
  };

  const handleNewService = (empresa) => {
    navigate(`/newservice/${empresa.nroEmpresa}/${empresa.razonSocial}`);
  };

  const handleViewContacts = (empresa) => {
    navigate(`/contacts/${empresa.nroEmpresa}`);
  };

  const handleNewContact = (empresa) => {
    handleModalContactOpen(
      "Nuevo Contacto para " + empresa.razonSocial,
      "Guardar",
      empresa
    );
  };

  const handleActionChange = (e, rowData) => {
    const selectedAction = e.target.value;
    console.log(rowData.nroEmpresa);
    switch (selectedAction) {
      case "nuevoServicio":
        // Realiza la acción de nuevo contacto
        handleNewService(rowData);
        break;
      case "nuevoContacto":
        // Realiza la acción de nuevo contacto
        handleNewContact(rowData);
        break;
        case "verContactos":
        // Realiza la acción de ver contactos
        handleViewContacts(rowData);
        break;
      case "editar":
        // Realiza la acción de edición
        handleEdit(rowData);
        break;
      case "eliminar":
        // Realiza la acción de eliminación
        handleDelete(rowData);
        break;
      default:
        // Maneja cualquier otra acción o valor no esperado
        break;
    }
  };

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <TableCell align="right">
          <Select
            value={""}
            onChange={(e) => handleActionChange(e, params.row)}
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value="nuevoServicio">Nuevo Servicio</MenuItem>
            <MenuItem value="nuevoContacto">Nuevo Contacto</MenuItem>
            <MenuItem value="verContactos">Ver Contactos</MenuItem>
            <MenuItem value="editar">Editar</MenuItem>
            <MenuItem value="eliminar">Eliminar</MenuItem>
          </Select>
        </TableCell>
      ),
    },
    { field: "nroEmpresa", headerName: "Id Cliente", width: 100 },
    { field: "razonSocial", headerName: "Razón Social", width: 200 },
    { field: "direccion", headerName: "Dirección", width: 200 },
    { field: "codigoPostal", headerName: "Código Postal", width: 100 },
    { field: "pais", headerName: "País", width: 100 },
    { field: "provincia", headerName: "Provincia", width: 100 },
    { field: "localidad", headerName: "Localidad", width: 150 },
    { field: "telefono", headerName: "Teléfono", width: 150 },
    { field: "emailFacturacion", headerName: "Email Facturación", width: 200 },
    { field: "cuit", headerName: "CUIT", width: 150 },
    { field: "tipoCliente", headerName: "Tipo de Cliente", width: 100 },
    { field: "web", headerName: "Web", width: 250 },
  ];

  return (
    <div>
      <Typography variant="h4" component="h1" gutterBottom>
        Lista de Empresas
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Button
          onClick={() => handleModalOpen("Agregar Empresa", "Agregar")}
          variant="outlined"
          startIcon={<AddCircleOutline />}
          style={{ marginBottom: 16 }}
        >
          Agregar Empresa
        </Button>
        <TextField
          label="Filtrar por nombre"
          value={filtroNombre}
          onChange={(e) => setFiltroNombre(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 16 }}
        />
        <Button
          onClick={handleSearch}
          variant="outlined"
          style={{ marginBottom: 16 }}
        >
          Buscar
        </Button>
      </Box>
      <DataGrid
        rows={empresas}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {modalTitle}
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              required
              fullWidth
              label="Id Cliente"
              value={nroEmpresa}
              onChange={(e) => setNroEmpresa(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              required
              fullWidth
              label="Razón Social"
              value={razonSocial}
              onChange={(e) => setRazonSocial(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Dirección"
              value={direccion}
              onChange={(e) => setDireccion(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Código Postal"
              value={codigoPostal}
              onChange={(e) => setCodigoPostal(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Provincia"
              value={provincia}
              onChange={(e) => setProvincia(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="País"
              value={pais}
              onChange={(e) => setProvincia(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Localidad"
              value={localidad}
              onChange={(e) => setLocalidad(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Teléfono"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Email Facturación"
              value={emailFacturacion}
              onChange={(e) => setEmailFacturacion(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="CUIT"
              value={cuit}
              onChange={(e) => setCuit(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Industria"
              value={tipoCliente}
              onChange={(e) => setTipoCliente(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Web"
              value={web}
              onChange={(e) => setWeb(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <Button type="submit" variant="contained" color="primary">
              {modalSubmitText}
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal open={modalContactOpen} onClose={handleModalContactClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {modalContactTitle}
          </Typography>

          <form onSubmit={handleContactSubmit}>
            <TextField
              fullWidth
              label="Id Cliente"
              value={nroEmpresa}
              variant="outlined"
              margin="normal"
              aria-readonly
            />

            <TextField
              fullWidth
              label="Apellido"
              value={nombreApellido}
              onChange={(e) => setNombreApellido(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Telefono Fijo"
              value={telefonoFijo}
              onChange={(e) => setTelefonoFijo(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Telefono Movil"
              value={telefonoMovil}
              onChange={(e) => setTelefonoMovil(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Posición"
              value={posicion}
              onChange={(e) => setPosicion(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="Sector"
              value={sector}
              onChange={(e) => setSector(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <TextField
              fullWidth
              label="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              margin="normal"
            />

            <Button type="submit" variant="contained" color="primary">
              {modalContactSubmitText}
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default Customers;
