import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Typography } from "@mui/material";

const ServicesCount = () => {
  const [cantidadServices, setCantidadServices] = useState(0);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "servicios")
        );
        const ServicesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCantidadServices(ServicesData.length);
      } catch (error) {
        console.error("Error fetching Services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <div
      style={{
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "orange",
      }}
    >
      <Typography variant="h1" component="h1" gutterBottom>
        {cantidadServices}
      </Typography>
    </div>
  );
};

export default ServicesCount;
