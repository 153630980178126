import { useEffect, useState } from "react";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {
  Box,
  Typography,
  TextField,
  Button,
  TableCell,
  Select,
  MenuItem,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import ServiceOrderPDF from "../../components/ServiceOrderPDF";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { orderBy } from "lodash";

const Services = () => {
  const navigate = useNavigate();
  const [servicios, setServicios] = useState([]);
  const [filtroServicioId, setFiltroServicioId] = useState("");
  const [filtroNroEmpresa, setFiltroNroEmpresa] = useState("");

  useEffect(() => {
    const fetchLast = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "servicios"),
            orderBy("servicioId", "desc"),
            limit(20)
          )
        );
        const serviciosData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServicios(serviciosData);
      } catch (error) {
        console.error("Error fetching instrumentos:", error);
      }
    };

    fetchLast();
  }, []);

  const handleSearch = async () => {
    const fetchServicios = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "servicios"),
            where("servicioId", ">=", filtroServicioId),
            where("nroEmpresa", "==", filtroNroEmpresa),
            limit(20)
          )
        );
        const serviciosData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServicios(serviciosData);
      } catch (error) {
        console.error("Error fetching servicios:", error);
      }
    };

    fetchServicios();
  };

  const handleAddServiceClick = () => {
    
    navigate("/customers");
  };

  const handleActionChange = (e, rowData) => {
    const selectedAction = e.target.value;

    switch (selectedAction) {
      case "imprimePDF":
        break;

      default:
        // Maneja cualquier otra acción o valor no esperado
        break;
    }
  };

  const columns = [
    {
      field: "pdf",
      headerName: "PDF",
      width: 20,
      sortable: false,
      renderCell: (params) => (
        <div>{params.row && <ServiceOrderPDF servicio={params.row} />}</div>
      ),
    },
    {
      field: "acciones",
      headerName: "Acciones",
      width: 100,
      sortable: false,

      renderCell: (params) => (
        <TableCell align="right">
          <Select
            value={""}
            onChange={(e) => handleActionChange(e, params.row)}
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value="Cambia">Cambia</MenuItem>
          </Select>
        </TableCell>
      ),
    },
    { field: "servicioId", headerName: "Servicio Id", width: 100 },
    { field: "revision", headerName: "Rev", width: 40 },
    { field: "ultimaRevision", headerName: "Fecha", width: 100 },
    { field: "nroEmpresa", headerName: "Id Cliente", width: 100 },
    { field: "estadoOferta", headerName: "Estado Of.", width: 100 },
    { field: "estadoServicio", headerName: "Estado Serv.", width: 100 },
    { field: "estadoFacturacion", headerName: "Estado Fact.", width: 100 },
    { field: "observaciones", headerName: "Observaciones", width: 200 },
    { field: "ordenCompra", headerName: "O Compra", width: 100 },
    { field: "remitoSalida", headerName: "Remito", width: 100 },
  ];
  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Lista de Servicios
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {/* <Button
          onClick={handleAddServiceClick}
          variant="outlined"
          startIcon={<AddCircleOutline />}
          style={{ marginBottom: 16 }}
        >
          Agregar Servicio
        </Button> */}
        <TextField
          label="Id Servicio"
          value={filtroServicioId}
          onChange={(e) => setFiltroServicioId(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 16 }}
        />
        <TextField
          label="Id Cliente"
          value={filtroNroEmpresa}
          onChange={(e) => setFiltroNroEmpresa(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 16 }}
        />
        <Button
          onClick={handleSearch}
          variant="outlined"
          style={{ marginBottom: 16 }}
        >
          Buscar
        </Button>
      </Box>

      <DataGrid
        rows={servicios}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </Box>
  );
};

export default Services;
