import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { db } from "../firebase/firebase";
import LogoEmpresa from "../images/Logo.jpg";
import QRCode from "qrcode";

const COL_ANCHO_1 = 50;
const COL_ANCHO_2 = 20;
const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 18,
    marginBottom: 5,
  },
  listItem: {
    marginBottom: 3,
  },
  tabla: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 20,
  },
  tablaFila: {
    margin: "auto",
    flexDirection: "row",
  },
  tablaColumna1: {
    // width: COL_ANCHO_1 + "%",
    width: "100%",
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tablaColumna2: {
    width: COL_ANCHO_2 + "%",
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tablaCeldaHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  anchoColumna1: {
    width: COL_ANCHO_1 + "%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  anchoColumna2: {
    width: COL_ANCHO_2 + "%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tablaCelda: {
    margin: 5,
    fontSize: 10,
  },
  
  qrCodeImage: {
    width: 250,
    height: 250,
    objectFit: "contain",
  },
});

const ServiceOrderPDF = ({ servicioId }) => {
  const [serviceOrder, setServiceOrder] = useState(null);

  const generateQRCode = async (text) => {
    try {
      const options = {
        errorCorrectionLevel: "H", // Ajusta el nivel de corrección de errores (L, M, Q, H)
        type: "image/png", // Cambia el formato de salida a PNG
        quality: 1, // Ajusta la calidad de la imagen (0 - 1)
        margin: 1, // Ajusta el margen del código QR
      };
      return await QRCode.toDataURL(text, options);
    } catch (error) {
      console.error("Error generating QR code:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchServiceOrder = async () => {
      try {
        const serviceQuery = query(
          collection(db, "servicios"),
          where("servicioId", "==", servicioId)
        );
        const serviceSnapshot = await getDocs(serviceQuery);
        const serviceData = serviceSnapshot.docs.map((doc) => doc.data())[0];

        if (!serviceData) {
          console.log(
            "No se encontró el servicio con el servicioId especificado."
          );
          return;
        }

        const instrumentsQuery = query(
          collection(db, "instrumentos"),
          where("servicioId", "==", servicioId)
        );
        const instrumentsSnapshot = await getDocs(instrumentsQuery);
        const instrumentsData = instrumentsSnapshot.docs.map((doc) =>
          doc.data()
        );

        setServiceOrder({ service: serviceData, instruments: instrumentsData });
      } catch (error) {
        console.error("Error fetching service with instruments:", error);
      }
    };

    fetchServiceOrder();
  }, [servicioId]);

  if (!serviceOrder) {
    return null; // O puedes mostrar un mensaje de carga o de no encontrado
  }
  return (
    <Document>
      <Page size="A4">
        <View style={{ padding: "15px" }}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ flex: 1 }}></View>
            <View style={{ flex: 2 }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{ width: "100px" }}
                  src={LogoEmpresa}
                  alt="Calins"
                />
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                  }}
                >
                  Orden de Servicio
                </Text>
              </View>
            </View>
            <View style={{ flex: 2 }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  border: "1px solid #000",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  OS- {serviceOrder.service.servicioId}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
          >
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                Cliente: {serviceOrder.service.clienteId}
              </Text>
            </View>
          </View>
          <View style={styles.tabla}>
            <View style={styles.tablaFila}>
              <View style={styles.tablaColumna1}>
                <Text style={styles.tablaCeldaHeader}>CERTIFICADO</Text>
              </View>
            </View>
            {serviceOrder.instruments.map((instrument, index) => (
              <View style={styles.tablaFila} key={index}>
                <View style={styles.anchoColumna1}>
                  <Text style={styles.tablaCelda}>
                    {instrument.certificadoNro}
                  </Text>
                </View>
                <View style={styles.anchoColumna1}>
                  <Image
                    style={styles.qrCodeImage}
                    src={generateQRCode(instrument.certificadoNro)}
                  />
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ServiceOrder = ({ servicio }) => {
  const fileName = `servicio_${servicio.servicioId}.pdf`;
  
  return (
    <div>
      <PDFDownloadLink
        document={<ServiceOrderPDF servicioId={servicio.servicioId} />}
        fileName={fileName}
      >
        Desc.
      </PDFDownloadLink>
    </div>
  );
};

export default ServiceOrder;
