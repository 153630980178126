import { DonutLarge, 
  Home, 
  People,  
  ConnectWithoutContact,
  Handyman,
  Engineering,
  QrCode2,
  Business
} from '@mui/icons-material';

export const sidebarItems = [
  {
    title: 'Inicio',
    path: '/',
    icon: <DonutLarge/>
  },
  {
    title: 'Usuarios',
    path: '/users',
    icon: <People/>
  },
  {
    title: 'Empresas',
    path: '/customers',
    icon: <Business/>,
    // children: [
    //   { title: 'Ver', path: '/customers' },
    //   { title: 'Agregar', path: '/customer/create' },
    //   { title: 'Editar', path: '/customer/edit' },
    // ]
  },
  {
    title: 'Contactos',
    path: '/contacts/0',
    icon: <ConnectWithoutContact/>
  },
  {
    title: 'Servicios',
    path: '/services',
    icon: <Handyman/>,
  },
  {
    title: 'Leer QR',
    path: '/qrcodereader',
    icon: <QrCode2/>
  },
  {
    title: 'Orden de Servicio',
    path: '/serviceorder',
    icon: <Engineering/>
  },
  
];

export const sidebarItemsTecnico = [
  {
    title: 'Inicio',
    path: '/',
    icon: <DonutLarge/>
  },
  {
    title: 'Contactos',
    path: '/contacts',
    icon: <ConnectWithoutContact/>
  },
  {
    title: 'Servicios',
    path: '/services',
    icon: <Handyman/>,
  },
  {
    title: 'Leer QR',
    path: '/qrcodereader',
    icon: <QrCode2/>
  },
  {
    title: 'Orden de Servicio',
    path: '/serviceorder',
    icon: <Engineering/>
  },
  
];

export const sidebarItemsCliente = [
  {
    title: 'Inicio',
    path: '/',
    icon: <DonutLarge/>
  },
  {
    title: 'Servicios',
    path: '/services',
    icon: <Handyman/>,
  },
  {
    title: 'Leer QR',
    path: '/qrcodereader',
    icon: <QrCode2/>
  },
  {
    title: 'Orden de Servicio',
    path: '/serviceorder',
    icon: <Engineering/>
  },
  
];

export const navbarAccountItems = [
  {
    title: 'Home',
    path: '/',
    icon: <Home/>
  },
];