export default {
  content: {
    maxWidth: 480,
    textAlign: 'center'
  },
  img404: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  }
}