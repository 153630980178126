import React from "react";
import { Container, Typography, Button, Box, Card, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { authAction } from "../../redux/authRedux";
import imgLogin from "../../images/calins.webp";

const Home = () => {
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem("userrol");
    localStorage.removeItem("username");
    dispatch(authAction.logout());
  };
  const imgStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000", // Puedes cambiar el color de fondo según tus necesidades
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };
  return (
    <Container maxWidth="xs" sx={{ marginTop: 10 }}>
      <Box style={imgStyle}>
        <img src={imgLogin} alt="CALINS S.A." style={imageStyle} />
        <Typography gutterBottom variant="h5">
          CALINS S.A.
        </Typography>
      </Box>
      <Card>
        <Typography
          variant="h6"
          component="h3"
          color="orange"
          align="center"
          margin={3}
        >
          Usuario "{localStorage.getItem("username")}" SIN ROL debe solicitar
          rol a un Administrador.
        </Typography>
        <Grid align="center" margin={2}>
          <Button
            align="center"
            color="primary"
            variant="outlined"
            onClick={logout}
            sx={{ margin: 2, align: "center" }}
          >
            Logout
          </Button>
        </Grid>
      </Card>
    </Container>
  );
};

export default Home;
