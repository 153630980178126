import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";
//import { orderBy } from "lodash";

const Contacts = () => {
  const { empresaId } = useParams();
  const [contacts, setContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubmitText, setModalSubmitText] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [nroEmpresa, setNroEmpresa] = useState("");
  const [nombreApellido, setNombreApellido] = useState("");
  const [telefonoFijo, setTelefonoFijo] = useState("");
  const [telefonoMovil, setTelefonoMovil] = useState("");
  const [posicion, setPosicion] = useState("");
  const [sector, setSector] = useState("");
  const [email, setEmail] = useState("");
  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroNroEmpresa, setFiltroNroEmpresa] = useState("");

  useEffect(() => {
    const fetchLast = async () => {
      try {
        let queryRef = collection(db, "contactos");
        console.log('filtra?',empresaId)
        if (empresaId === "0") {
          console.log('no');
          queryRef = query(queryRef, limit(20));
          
        
        } else {
          console.log('si');
          queryRef = query(queryRef, where("nroEmpresa", "==", empresaId), limit(20));
        }
        
  
        const querySnapshot = await getDocs(queryRef);
        const filteredContactos = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContacts(filteredContactos);
      } catch (error) {
        console.error("Error fetching contactos:", error);
      }
    };
  
    fetchLast();
  }, [empresaId]); // Agrega nroEmpresa como una dependencia
  

  const handleSearch = async () => {
    const fetchContacts = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "contactos"),
            where("nombreApellido", ">=", filtroNombre),
            where("nroEmpresa","==", filtroNroEmpresa),
            limit(20)
          )
        );
        const contactsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const filteredContactos = contactsData.filter((contacto) => {
          const nombre = contacto.nombreApellido.toLowerCase();
          const nroEmpresa = contacto.nroEmpresa;
          const filtroNombreLowerCase = filtroNombre.toLowerCase();
          return (
            nombre.includes(filtroNombreLowerCase) ||
            nroEmpresa.includes(filtroNombre)
          );
        });

        setContacts(filteredContactos);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  };

  const handleModalOpen = (title, submitText, contact = null) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalSubmitText(submitText);
    setSelectedContact(contact);
    setNroEmpresa(contact ? contact.nroEmpresa : "");
    setNombreApellido(contact ? contact.nombreApellido : "");
    setTelefonoFijo(contact ? contact.telefonoFijo : "");
    setTelefonoMovil(contact ? contact.telefonoMovil : "");
    setPosicion(contact ? contact.posicion : "");
    setSector(contact ? contact.sector : "");
    setEmail(contact ? contact.email : "");
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedContact(null);
    setNroEmpresa("");
    setNombreApellido("");
    setTelefonoFijo("");
    setTelefonoMovil("");
    setPosicion("");
    setSector("");
    setEmail("");
  };

  const handleNroEmpresaChange = (event) => {
    setNroEmpresa(event.target.value);
  };

  const handleApellidoNombreChange = (event) => {
    setNombreApellido(event.target.value);
  };

  const handleTelefonoFijoChange = (event) => {
    setTelefonoFijo(event.target.value);
  };

  const handleTelefonoMovilChange = (event) => {
    setTelefonoMovil(event.target.value);
  };

  const handlePosicionChange = (event) => {
    setPosicion(event.target.value);
  };

  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedContact) {
      // Update existing contact
      const contactRef = doc(db, "contactos", selectedContact.id);
      await updateDoc(contactRef, {
        nroEmpresa,
        nombreApellido,
        telefonoFijo,
        telefonoMovil,
        posicion,
        sector,
        email,
      });
    } else {
      // Add new contact
      await addDoc(collection(db, "contactos"), {
        nroEmpresa,
        nombreApellido,
        telefonoFijo,
        telefonoMovil,
        posicion,
        sector,
        email,
      });
    }

    handleModalClose();
  };

  const handleDelete = async (contactId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este contacto?")) {
      try {
        const contactRef = doc(db, "contactos", contactId);
        await deleteDoc(contactRef);
      } catch (error) {
        console.error("Error deleting contact:", error);
      }
    }
  };

  return (
    <Box>
      <Typography variant="h4">Contactos</Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <TextField
          label="Filtrar por nombre"
          value={filtroNombre}
          onChange={(e) => setFiltroNombre(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 16 }}
        />
        <TextField
          label="Id Cliente"
          value={filtroNroEmpresa}
          onChange={(e) => setFiltroNroEmpresa(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 16 }}
        />
        <Button
          onClick={handleSearch}
          variant="outlined"
          style={{ marginBottom: 16 }}
        >
          Buscar
        </Button>
      </Box>

      <DataGrid
        rows={contacts}
        columns={[
          {
            field: "acciones",
            headerName: "Acciones",
            renderCell: (params) => (
              <div>
                <IconButton
                  onClick={() =>
                    handleModalOpen("Editar contacto", "Actualizar", params.row)
                  }
                >
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDelete(params.row.id)}>
                  <Delete />
                </IconButton>
              </div>
            ),
          },
          { field: "nroEmpresa", headerName: "Id Cliente", width: 100 },
          {
            field: "nombreApellido",
            headerName: "Nombre y Apellido",
            width: 200,
          },
          { field: "telefonoFijo", headerName: "Teléfono Fijo", width: 150 },
          { field: "telefonoMovil", headerName: "Teléfono Móvil", width: 150 },
          { field: "posicion", headerName: "Posición", width: 150 },
          { field: "sector", headerName: "Sector", width: 150 },
          { field: "email", headerName: "Email", width: 250 },
        ]}
              
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        
      />

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: 400,
            width: "100%",
          }}
        >
          <Typography variant="h6" component="h2" mb={2}>
            {modalTitle}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nro. Empresa"
              fullWidth
              value={nroEmpresa}
              onChange={handleNroEmpresaChange}
              required
              mb={2}
            />
            <TextField
              label="Apellido y Nombre"
              fullWidth
              value={nombreApellido}
              onChange={handleApellidoNombreChange}
              required
              mb={2}
            />
            <TextField
              label="Teléfono Fijo"
              fullWidth
              value={telefonoFijo}
              onChange={handleTelefonoFijoChange}
              required
              mb={2}
            />
            <TextField
              label="Teléfono Móvil"
              fullWidth
              value={telefonoMovil}
              onChange={handleTelefonoMovilChange}
              required
              mb={2}
            />
            <TextField
              label="Posición"
              fullWidth
              value={posicion}
              onChange={handlePosicionChange}
              required
              mb={2}
            />
            <TextField
              label="Sector"
              fullWidth
              value={sector}
              onChange={handleSectorChange}
              required
              mb={2}
            />
            <TextField
              label="Email"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              required
              mb={2}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {modalSubmitText}
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default Contacts;
