import React from "react";
import RolesChart from "../../components/RolesChart";
import ServicesChart from "../../components/ServicesChart";
import ServicesCount from "../../components/ServicesCount";
import ClientCount from "../../components/ClientCount";
import ContactsCount from "../../components/ContactsCount";
import { Grid, Container, Card, Typography } from "@mui/material";

const Dashboard = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ minWidth: 200, minHeight: 240 }}>
            <Typography align="center">Usuarios por Roles</Typography>
            <RolesChart />
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card sx={{ minWidth: 200, minHeight: 240 }}>
            <Typography align="center">Servicios</Typography>
            <ServicesChart/>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 200, minHeight: 240 }}>
            <Typography align="center">Total de Servicios</Typography>
            <ServicesCount/>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 275, minHeight: 240 }}>
            <Typography align="center">Contactos</Typography>
            <ContactsCount/>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 275, minHeight: 240 }}>
            <Typography align="center">Empresas</Typography>
            <ClientCount />
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 275, minHeight: 240 }}>
            <Typography align="center">Ordenes de Servicio Pendientes</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 275, minHeight: 240 }}>
            <Typography align="center">Servicios</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 275, minHeight: 240 }}>
            <Typography align="center">Certificados de Calibración</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 275, minHeight: 240 }}>
            <Typography align="center">Trabajos Terminados</Typography>
          </Card>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Dashboard;
