import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "./firebase";
import { addDoc, collection, query, where, getDocs } from "firebase/firestore";
import { getUser } from "./Users";

// Objeto para manejar la autenticación
const authFirebase = {
  // Registro de usuario
  signUp: async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await addDoc(collection(db, "users"), {
        correo: email.toLowerCase(),
        rol: "sinrol",
      });
      localStorage.setItem("username", email.toLowerCase());
      localStorage.setItem("userrol", "sinrol");
      return userCredential;
    } catch (error) {
      throw error;
    }
  },

  // Inicio de sesión de usuario
  signIn: async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential;
    } catch (error) {
      throw error;
    }
  },

  // Inicio de sesión de usuario con Google
  signInWithGoogle: async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);

      //Busca si lo tiene en la colección users
      const querySnapshot = await getUser(userCredential.user.email);

      if (querySnapshot.empty) {
        await addDoc(collection(db, "users"), {
          correo: userCredential.user.email,
          rol: "sinrol",
        });
      }

      return userCredential;
    } catch (error) {
      throw error;
    }
  },

  // Cierre de sesión de usuario
  signOut: async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  },

  // Observador de cambio de estado de autenticación
  onAuthStateChanged: (callback) => {
    return onAuthStateChanged(auth, callback);
  },
};

export default authFirebase;
