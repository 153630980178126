// CONSTANTS
const AUTH_INITIAL = 'AUTH_INITIAL';
const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
const AUTH_LOGIN_SUCCESSFUL = 'AUTH_LOGIN_SUCCESSFUL';
const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_REGISTER_START = 'AUTH_REGISTER_START';
const AUTH_REGISTER_SUCCESSFUL = 'AUTH_REGISTER_SUCCESSFUL';
const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';

const stateInitial = {
  isLoading: false,
  isAuth: false,
  isSuccessful: false,
  user: null,
  error: null,
};

// SELECTORS
export const authSelector = {
  isLoading: (state) => state.auth.isLoading,
  isSuccessful: (state) => state.auth.isSuccessful,
  isAuth: (state) => state.auth.isAuth,
  user: (state) => state.auth.user,
  error: (state) => state.auth.error,
};

// ACTIONS
export const authAction = {
  initial: () => ({
    type: AUTH_INITIAL
  }),
  login: (payload) => ({
    type: AUTH_LOGIN_START,
    payload
  }),
  loginSuccessful: (payload) => ({
    type: AUTH_LOGIN_SUCCESSFUL,
    payload
  }),
  register: (payload) => ({
    type: AUTH_REGISTER_START,
    payload
  }),
  logout: () => ({
    type: AUTH_LOGOUT_START
  }),
  
}

// REDUCERS
export const authReducer = (state = stateInitial, action) => {
  //console.log("reducer auth", action, state);
  switch (action.type) {
    case AUTH_INITIAL:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null,
      };

    case AUTH_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        isAuth: false,
        user: null,
        error: null,
      };

    case AUTH_LOGIN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        isAuth: true,
        user: action.payload,
      };

    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        isAuth: false,
        user: null,
        error: action.payload,
      };

    case AUTH_REGISTER_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case AUTH_REGISTER_SUCCESSFUL:
      
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        
      };

    case AUTH_REGISTER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AUTH_LOGOUT_START:
      return {
        isLoading: false,
        isAuth: false,
        isSuccessful: false,
        user: null,
        error: null,
      };
    
    default:
      return state;
  }
  
};

// REDUCERS
export const loginReducer = (payload) => ({
  type: AUTH_LOGIN_SUCCESSFUL,
  payload
});

export const registerReducer = () => ({
  type: AUTH_REGISTER_SUCCESSFUL
});

export const logoutReducer = () => {
  localStorage.removeItem('auth');
};

