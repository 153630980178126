const Typography = (theme) => ({
  MuiTypography: {
    styleOverrides: {
      paragraph: {
        marginBottom: theme.spacing(2)
      },
      gutterBottom: {
        marginBottom: theme.spacing(1)
      }
    }
  }
});

export default Typography;