import React from "react";
import RolesChart from "../../components/RolesChart";
import { Grid, Container, Card, Typography } from "@mui/material";

const DashboardCliente = () => {
  return (
    <Container maxWidth="md">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ minWidth: 200, minHeight: 240 }}>
            <Typography align="center">Certificados</Typography>
            <RolesChart />
          </Card>
        </Grid>

      </Grid>
    </Container>
  );
};

export default DashboardCliente;
