import React, { useState } from 'react';
import {QrReader} from 'react-qr-reader';

const QRCodeReader = () => {
  const [data, setData] = useState('No result');

  // const handleScan = (data) => {
  //   if (data) {
  //     setQrData(data);
  //   }
  // };

  // const handleError = (error) => {
  //   console.error(error);
  // };

  return (
    <div>
      {/* <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        constraints={{facingMode: 'environment'}}
      />
      <p>Dato del QR leído: {qrData}</p> */}
      <QrReader
        constraints={{facingMode: 'environment'}}
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
      <p>{data}</p>
    </div>
  );
};

export default QRCodeReader;
